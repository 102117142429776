/* eslint-disable react/button-has-type */
import React, { useState, useRef } from 'react';

const CustomAccordion = ({ children, title = 'Header', id = '' }) => {
  const [clicked, setClicked] = useState(false);
  const contentEl = useRef();

  const handleToggle = () => {
    setClicked((prev) => !prev);
  };

  return (
    <li className={`sf-accordion-item ${clicked ? 'active' : ''}`}>
      <button className="btn-accordion" onClick={handleToggle}>
        <span>{title}</span>
        <span className="control">{clicked ? '—' : '+'} </span>
      </button>

      <div
        ref={contentEl}
        id={id}
        className="answer_wrapper"
        style={
          clicked
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <div className="answer">{children}</div>
      </div>
    </li>
  );
};

export default CustomAccordion;
