/* eslint-disable no-undef */
import axios from 'axios';

const paths = {
  completefunding: '/wallet/completewalletfunding',
  subscribe: '/newsletter/subscribe'
};

const fetchBackend = async (endpoint, extra, method, body,
  pQuery, param, multipart, responseType) => {
  const headers = {
    'X-API-KEY': process.env.REACT_APP_API_KEY,
    'Content-Type': multipart ? 'multipart/form-data' : 'application/json'
  };
  const path = paths[endpoint] || endpoint;
  let url = `${process.env.REACT_APP_API_BASE_URL}${path}`;

  if (extra) {
    url += `/${extra}`;
  }

  if (param) {
    url += `/${param}`;
  }

  if (pQuery) {
    let paramsArray = Object.keys(pQuery)
      .map((key) => pQuery[key] && `${encodeURIComponent(key)}=${encodeURIComponent(pQuery[key])}`);

    paramsArray = paramsArray.filter((item) => item);
    url += `?${paramsArray.join('&')}`;
  }

  const options = {
    url, method, headers
  };

  if (responseType) {
    options.responseType = responseType;
  }

  if (body) {
    options.data = body;
  }

  return axios(options)
    .then((res) => res, (err) => err.response);
};

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} pQuery
 * @param {string} param
 * @param {boolean} auth
 */
export const get = ({
  endpoint, extra = null, pQuery = null, param = null
}) => fetchBackend(endpoint, extra, 'GET', null, pQuery, param);

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} body
 * @param {boolean} auth
 * @param {boolean} multipart
 */
export const post = ({
  endpoint, extra = null, body = null, multipart = false, responseType = null
}) => fetchBackend(endpoint, extra, 'POST', body, null, null, multipart, responseType);

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} body
 * @param {string} param
 * @param {boolean} auth
 * @param {boolean} multipart
 */
export const patch = ({
  endpoint, extra = null, body = null, param = null, multipart = false
}) => fetchBackend(endpoint, extra, 'PATCH', body, null, param, multipart);

/**
 *
 * @param {string} endpoint
 * @param {string} extra
 * @param {object} body
 * @param {string} param
 * @param {boolean} auth
 */
export const del = ({
  endpoint, param = null, extra = null, body = null
}) => fetchBackend(endpoint, extra, 'DELETE', body, null, param);
