import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import CompleteFunding from './components/CompleteFunding';
import Privacy from './components/Privacy';
import Index from './components/Index';
import Referral from './components/Referral';
import ReferralFAQ from './components/ReferralFAQ';
import ScrollToTop from './ScrollToTop';

const App = () => {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <div>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/referral" exact component={Referral} />
          <Route path="/referral/faqs" exact component={ReferralFAQ} />
          <Route path="/complete-funding" exact component={CompleteFunding} />
          <Route path="/privacy" exact component={Privacy} />
          <Route render={() => <center>Error 404. Page not found.</center>} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
