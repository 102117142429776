/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import Footer from './layouts/Footer';

function Privacy() {
  return (
    <>
      <section className="section-1">
        <center className="m-t-20">
          <a href="/">
            <img className="logo" src={Logo} alt="logo" />
          </a>
          <p className="big-text">Terms And Conditions</p>
        </center>
        <div className="content m-t-40">
          <p>
            Please carefully read the terms and conditions ("Terms and
            Conditions") below before signing-up on the CampusRunz Mobile
            Application/Website to place any order for any of the Goods or
            Services, or perform transactions from{' '}
            <Link to="/" className="inline-url">
              https://www.campusrunz.com
            </Link>{' '}
            or the CampusRunz Mobile Application. By signing-up on the
            CampusRunz Mobile Application/Website, whereas it is by phone,
            through our mobile applications or by any other available channel,
            you agree to be bound by these Terms and Conditions.
          </p>
          <p className="cl-head">Contents</p>
          <div className="list-block">
            <ul>
              <li>About this Notice</li>
              <li>CampusRunz Introduction</li>
              <li>Definitions</li>
              <li>Sign-up / The data we collect about you</li>
              <li>Ordering</li>
              <li>How we use your personal data</li>
              <li>How we share your personal data</li>
              <li>Data Security</li>
              <li>Your Legal Rights</li>
              <li>Further Details</li>
            </ul>
          </div>
          <p className="cl-head">Section 1: About this Notice</p>
          <p>
            This Terms and Condition Notice provides information on how
            CampusRunz collects and processes your personal data when you visit
            our website or mobile applications.
          </p>
          <p className="cl-head">Section 2: CampusRunz Introduction</p>
          <p>
            We are CampusRunz, a brand of https://www.campusrunz.com, unless
            otherwise stated. CampusRunz is a mobile application that offers
            utilitarian services such as Transportation, Accommodation,
            Marketplace, Foodstuffs market, Maintenance and Laundry to provide
            convenience, timely and efficient service, and meet the everyday
            needs of the users.
          </p>
          <p className="cl-head">Section 3: Definitions</p>
          <p>
            3.1. "Agreement" references these Terms and Conditions (T&Cs), the
            Privacy Policy, any payment instructions provided to you and any
            other form we might provide to you;
          </p>
          <p>
            3.2. By "Privacy Policy" we mean the Terms and Conditions accessible
            on our Mobile Application/ Website which details how we collect and
            store your personal data;
          </p>
          <p>
            3.3. "you", "your" and "yours" are references to you, the person
            accessing this Mobile Application/Website, and placing the orders
            for the Goods or Services we display on it. Access through any other
            channel provided by CampusRunz will also bound you to these Terms
            and Conditions;
          </p>
          <p>
            3.4. "we", "us", "our", and "CampusRunz" are references to the
            Company;
          </p>
          <p>
            3.5. "Goods" is a reference to any goods which we may offer for sale
            from our Website from at a given time;
          </p>
          <p>
            3.6. "Service" or "Services" is a reference to any service which we
            may supply and which you may request via our Mobile
            Application/Website;
          </p>
          <p>
            3.7. "Business Owner" is the manager of each module/service on the
            platform;
          </p>
          <p>
            3.8. "Partner/Vendor" is a third party, which has agreed to
            co-operate with the Company to prepare and/or deliver the Goods or
            Services;
          </p>
          <p>
            3.9. "Product Delivery" references goods and any form of delivery
            service, both provided by our Partner/Vendor and for both of which
            our Partner/Business Owner take full responsibility;
          </p>
          <p>
            3.10. "Website" is a reference to our Website
            https://www.CampusRunz.com, our mobile applications or any other
            platform we will choose to offer our Goods or services.
          </p>
          <p className="cl-head">
            Section 4: Sign-up / The data we collect about you
          </p>
          <p>
            Customer signs up by providing us with basic information/data
            required to perform and authenticate transactions as stated by
            regulatory agencies in Nigeria. We collect your personal data in
            order to send you token to verify you during sign-up, authenticate
            transactions done by customers, and provide and continually improve
            our products and services. We may collect, use, store and transfer
            the following different kinds of personal data about you for
            marketing and personal data optimization purposes.
          </p>
          <p>
            Please note that these data are collected during Sign-up and
            customer agrees to have provided us with data for our use once
            customer have accepted our terms and condition and proceeded to
            sign-up.
          </p>
          <p>
            Also, customer stops getting messages and information from us as
            customer’s data is deleted from the database immediately customer
            stops being a customer of the platform; either by sending a mail to
            info@campusrunz.com, stating that customer is no longer interested
            in using CampusRunz and requesting that account be closed or by
            deleting the application (as our periodic data cleansing formation
            will enable us know who has exited the platform by deleting the
            Mobile Application).
          </p>
          <p>In addition to the above:</p>
          <div className="list-block">
            <ul>
              <li>
                Information you provide to us: We receive and store the
                information you provide to us including your identity data,
                contact data, delivery address and financial data.
              </li>
              <li>
                Information on your use of our website and/or mobile
                applications: We automatically collect and store certain types
                of information regarding your use of CampusRunz including
                information about your searches, views, downloads and purchases.
              </li>
              <li>
                Information from third parties and publicly available sources:
                We may receive information about you from third parties
                including our carriers; payment service providers;
                merchants/brands; and advertising service providers
              </li>
            </ul>
          </div>
          <p className="cl-head">Section 5: Ordering</p>
          <p>
            By placing an order through our Mobile Application/Website, you
            enter into an agreement with CampusRunz with respect to the
            processing of that order and forwarding it to the Participating
            Vendor. If you are paying online, CampusRunz is also responsible for
            any returns or refunds. However, the Vendor and Business Owner
            remains responsible for the preparation, quality and delivery of
            your order. You agree to take particular care when providing us with
            your details and warrant that these details are accurate and
            complete at the time of ordering. You also warrant that the credit
            or debit card details that you provide are for your own credit or
            debit card and that you have sufficient funds to make the payment.
          </p>
          <p className="cl-head">Section 6: How we use your personal data</p>
          <p>
            We use your personal data to operate, provide, develop and improve
            the products and services that we offer, including the following:
          </p>
          <div className="list-block">
            <ul>
              <li>Registering you as a new customer.</li>
              <li>Processing and delivering your orders.</li>
              <li>Managing your relationship with us.</li>
              <li>
                Enabling you to participate in promotions, competitions and
                surveys.
              </li>
              <li>
                Improving our website, applications, products and services
              </li>
              <li>
                Recommending/advertising products or services which may be of
                interest to you.
              </li>
              <li>
                Complying with our legal obligations, including verifying your
                identity where necessary.
              </li>
              <li>Detecting fraud.</li>
            </ul>
          </div>
          <p className="cl-head">Section 7: How we share your personal data</p>
          <p>
            We may need to share your personal data with third parties for the
            following purposes:
          </p>
          <div className="list-block">
            <ul>
              <li>
                Sale of products and services: In order to deliver your products
                and services purchased on our marketplace from third parties, we
                may be required to provide your personal data to such third
                parties.
              </li>
              <li>
                Working with third party service providers: We engage third
                parties to perform certain functions on our behalf. Examples
                include fulfilling orders for products or services, delivering
                packages, analyzing data, providing marketing assistance,
                processing payments, transmitting content, assessing and
                managing credit risk, and providing customer service.
              </li>
              <li>
                Business transfers: As we continue to develop our business, we
                might sell or buy other businesses or services. In such
                transactions, customer information may be transferred together
                with other business assets.
              </li>
              <li>
                Detecting fraud and abuse: We release account and other personal
                data to other companies and organizations for fraud protection
                and credit risk reduction, and to comply with the law.
              </li>
            </ul>
          </div>
          <p>When we share your personal data with third parties, we:</p>
          <div className="list-block">
            <ul>
              <li>
                require them to agree to use your data in accordance with the
                terms of this Terms and Conditions, and in accordance with the
                law; and
              </li>
              <li>
                only permit them to process your personal data for specified
                purposes and in accordance with our instructions. We do not
                allow our third-party service providers to use your personal
                data for their own purposes.
              </li>
            </ul>
          </div>
          <p className="cl-head">Section 8: Data Security</p>
          <p>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality. We have put in place procedures to
            deal with any suspected personal data breach and will notify you and
            any applicable regulator of a breach where we are legally required
            to do so.
          </p>
          <p className="cl-head">Section 9: Your Legal Rights</p>
          <p>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us. Under certain circumstances, you
            have rights under data protection laws in relation to your personal
            data, including the right to access, correct or erase your personal
            data, object to or restrict processing of your personal data, and
            unsubscribe from our emails and newsletters.
          </p>
          <p className="cl-head">Section 10: Further Details</p>
          <p>
            If you are looking for more information on how we process your
            personal data, or you wish to exercise your legal rights in respect
            of your personal data, please contact{' '}
            <span className="m-b">info@campusrunz.com.</span>
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Privacy;
