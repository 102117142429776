/* eslint-disable import/prefer-default-export */
export const FAQS = [
  {
    question: 'What is the Campusrunz Referral program ?',
    answer:
      'The Campusrunz Referral Program is a way for you to earn rewards by referring friends, family, and colleagues to our products/services. When your referrals make a purchase, both you and your referred friend can benefit.'
  },
  {
    question: 'How does the referral program work?',
    answer:
      'It is simple! You receive a unique referral code that you can share with your family, friends and colleagues. You get rewarded twice. Firstly, when they sign up using your unique code and once again when they make three purchases.'
  },
  {
    question: 'How do I refer to other people?',
    answer:
      "Share your unique referral code with your friends, family, or colleagues. They'll need to use that code to sign up."
  },
  {
    question: 'How will I know if my referral makes a purchase?',
    answer:
      "You'll receive an email confirming that your referral has made a purchase using your unique code. You can also log into your account to track your referrals and rewards on the referral page."
  },
  {
    question: 'Can I refer to multiple people?',
    answer:
      "Absolutely! The more people you refer to, the more rewards you can earn. There's often no limit to the number of referrals you can make."
  },
  {
    question: 'Can I refer myself or create accounts to get rewards?',
    answer:
      "No, the referral program is designed to reward genuine referrals. Referring yourself or creating multiple accounts to abuse the program's rewards is not allowed and may result in disqualification."
  },
  {
    question: 'How will I receive my referral rewards?',
    answer:
      'Once you start referring users, a separate wallet will be created. An email notification will be sent notifying you of the direct deposits sent to your referral wallet.'
  },
  {
    question: 'What should I do if I have issues with my referral rewards?',
    answer:
      "If you encounter any issues with your referral rewards, don't hesitate to contact our customer support team at hello@campusrunz.com.ng or 09065406276. We'll be happy to assist you."
  },
  {
    question: 'How can I spend my referral rewards?',
    answer:
      'Your referral rewards are only spent via in-app purchases such as Food stuffs, Fast food, Cooking Gas etc. '
  }
];
