/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import DownArrow from '../assets/images/down-arrow.png';
import TextLogo from '../assets/images/text-logo.png';
import Young from '../assets/images/young-and-happy.png';
import Smiling from '../assets/images/smiling-attendant.png';
import House from '../assets/svg/house.svg';
import Laundry from '../assets/svg/laundry.svg';
import Transport from '../assets/svg/bus-front.svg';
import Marketplace from '../assets/svg/marketplace.svg';
import Engineering from '../assets/svg/engineering.svg';
import Foodstuff from '../assets/svg/foodstuff.svg';
import FinishFresh from '../assets/images/finish-school-fresh.png';
import MobileApp from '../assets/images/mobile-app.png';
import AppleStoreBtn from '../assets/images/apple-store.png';
import GoogleStoreBtn from '../assets/images/google-store.png';
import Footer from './layouts/Footer';

function Referral() {
  const gotoAdminApp = () => {
    // eslint-disable-next-line no-undef
    window.location.href = 'https://www.portal.campusrunz.com/create-profile';
  };

  return (
    <>
      <div className="banner ref">
        <center
          style={{
            paddingTop: '80vh'
          }}
        >
          <img className="logo" src={Logo} alt="logo" />
          <h1 className="mb-2">Referral Campaign Ad</h1>
          <Link className="link-btn" to="/referral/faqs">
            Learn More
          </Link>
        </center>
        <img className="arrow-down" src={DownArrow} alt="arrow down" />
      </div>

      <section className="section-1">
        <center className="m-t-20">
          <img className="logo" src={Logo} alt="logo" />
          <p className="big-text">
            I’m&nbsp;
            <img className="text-logo" src={TextLogo} alt="logo" />
            &nbsp;with only one mission.
          </p>
          <p className="md-font">
            ...To be your reliable destiny helper on campus.
          </p>
        </center>
        <div className="content m-t-40">
          <div className="row">
            <div className="col-6">
              <center>
                <img
                  className="card-image"
                  src={Smiling}
                  alt="smiling attendant"
                />
                <p>
                  I don’t know any of your friends who can go on errand seven
                  (7) times for you 🤷‍♂️. CampusRunz is here to do as much errands
                  you can think of within a minute. If you don’t have such a
                  friend, download our App now.
                </p>
                <div className="buttons m-t-40">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.campusrunsapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={GoogleStoreBtn}
                      alt="google app store button"
                      className="app-btn m-r-10"
                    />
                  </a>
                  <img
                    src={AppleStoreBtn}
                    alt="apple app store button"
                    className="app-btn"
                  />
                </div>
              </center>
            </div>
            <div className="col-6">
              <center>
                <img className="card-image" src={Young} alt="young and happy" />
                <p>
                  Hello business owner, we are in business as well and we know
                  the worry about how to sell off- and on-campus. This is where
                  all students on the campus would be eventually; Sign-up as a
                  merchant today on CampusRunz and easily connect with your
                  customers.
                </p>
                <button className="m-t-40" type="button" onClick={gotoAdminApp}>
                  Sign Up Now!
                </button>
              </center>
            </div>
          </div>
        </div>
      </section>

      <section className="section-2">
        <center className="m-b-40">
          <p className="lg-font brand-color m-b-10">
            <b>Life is hard,</b>
            &nbsp;especially on your campus; but you know,
          </p>
          <p className="md-font">
            We have put together a list of all the services you would need.
          </p>
        </center>
        <div className="modules">
          <div className="row">
            <div className="col-4">
              <div className="module-card">
                <div className="round-icon">
                  <img src={House} alt="accommodation" />
                </div>
                <p>Accommodation</p>
              </div>
            </div>
            <div className="col-4">
              <div className="module-card">
                <div className="round-icon">
                  <img src={Laundry} alt="laundry" />
                </div>
                <p>Laundry</p>
              </div>
            </div>
            <div className="col-4">
              <div className="module-card">
                <div className="round-icon">
                  <img src={Transport} alt="transportation" />
                </div>
                <p>Transport</p>
              </div>
            </div>
            <div className="col-4">
              <div className="module-card">
                <div className="round-icon">
                  <img src={Marketplace} alt="maketplace" />
                </div>
                <p>Marketplace</p>
              </div>
            </div>
            <div className="col-4">
              <div className="module-card">
                <div className="round-icon">
                  <img
                    src={Engineering}
                    alt="Maintenance and Electrical Services"
                  />
                </div>
                <p>Maintenance & Electrical Services</p>
              </div>
            </div>
            <div className="col-4">
              <div className="module-card">
                <div className="round-icon">
                  <img src={Foodstuff} alt="food stuff" />
                </div>
                <p>Food Stuff</p>
              </div>
            </div>
          </div>
        </div>
        <center>
          <p className="md-font">
            If there is any service we have missed, please click here to notify
            us.
          </p>
        </center>
      </section>

      <section className="section-3">
        <div className="big-text">
          <img src={FinishFresh} alt="finsih school fresh" />
          <p className="lg2-font m-t-10">
            …na school you come, join the sharp geng 😉
          </p>
        </div>
        <div className="floating-image">
          <div className="image-box">
            <img src={MobileApp} alt="mobile app" />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Referral;
