/* eslint-disable react/destructuring-assignment */
import React from 'react';

import bouncingLogo from '../../assets/images/logo.png';

const IsLoading = (props) => (
  <div className="overlay">
    <center className="inner-box">
      <img src={bouncingLogo} alt="bouncing Logo" />
      {props.message ? `${props.message}...` : 'Loading...'}
    </center>
  </div>
);

export default IsLoading;
