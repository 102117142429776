/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import Back from '../assets/svg/back.svg';
import Footer from './layouts/Footer';
import FinishFresh from '../assets/images/finish-school-fresh.png';
import MobileApp from '../assets/images/mobile-app.png';
import CustomAccordion from './layouts/CustomAccordion';
import { FAQS } from '../api-services/content';

function ReferralFAQ() {
  const openAccordion = (e) => {
    const accordion = e.target;
    accordion.classList.toggle('active');
    const panel = accordion.nextElementSibling;

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = `${panel.scrollHeight}px`;
    }
  };

  return (
    <>
      <header className="nav-header">
        <div className="nav-box">
          <div className="nav-link">
            <img className="back-btn" src={Back} alt="back" />
            <Link to="/" className="home-btn">
              Home
            </Link>
          </div>
          <nav className="main-nav">
            <div>
              <h3>Referral Program</h3>
              <p>...Get more for your network</p>
            </div>
            <img className="logo" src={Logo} alt="logo" />
          </nav>
        </div>
      </header>

      <section className="faqs">
        <div className="ads">
          <div className="ad-box">Campaign Adspace</div>
        </div>
        <div className="referral-box">
          <p className="title">Referral Program FAQ</p>

          {FAQS.length > 0 &&
            FAQS.map((faq, index) => (
              <CustomAccordion id={index + 1} title={faq?.question}>
                <p>{faq?.answer}</p>
              </CustomAccordion>
            ))}
        </div>
      </section>

      <section className="section-3">
        <div className="big-text">
          <img src={FinishFresh} alt="finsih school fresh" />
          <p className="lg2-font m-t-10">…enjoy the best of school</p>
        </div>
        <div className="floating-image">
          <div className="image-box">
            <img src={MobileApp} alt="mobile app" />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default ReferralFAQ;
