import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import AppleStoreBtn from '../../assets/images/apple-store.png';
import GoogleStoreBtn from '../../assets/images/google-store.png';
import Instagram from '../../assets/svg/instagram-icon.svg';
import Like from '../../assets/svg/like.svg';
import Twitter from '../../assets/svg/twitter-icon.svg';
import { post } from '../../api-services/fetch';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState('');

  const subscribe = async () => {
    if (!email) {
      return;
    }

    if (
      email &&
      !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)
    ) {
      setMsg('Please enter a valid email address.');
      setShowModal(true);
      return;
    }

    setIsProcessing(true);
    const res = await post({ endpoint: 'subscribe', body: { email } });
    setIsProcessing(false);

    if (res?.status === 201) {
      setMsg(res?.data?.message);
      setEmail('');
    } else {
      setMsg(
        res?.data?.error?.message || 'Something went wrong. Please try again.'
      );
    }
    setShowModal(true);
  };

  const gotoAdminApp = () => {
    // eslint-disable-next-line no-undef
    window.location.href = 'https://www.portal.campusrunz.com/create-profile';
  };

  return (
    <>
      <Modal
        classNames={{ modal: 'max-w-400' }}
        open={showModal}
        onClose={() => setShowModal(false)}
        center
      >
        <h4>Newsletter Subscription</h4>
        <center>
          <p className="m-t-20">{msg}</p>
          <button
            onClick={() => setShowModal(false)}
            className="btn btn-large w-100 m-t-20"
            type="button"
          >
            Okay
          </button>
        </center>
      </Modal>

      <footer>
        <div className="footer-container">
          <div className="buttons flex m-b-20">
            <a
              href="https://play.google.com/store/apps/details?id=com.campusrunsapp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={GoogleStoreBtn}
                alt="google app store button"
                className="app-btn m-r-10"
              />
            </a>
            <a
              href="https://apps.apple.com/ng/app/campusrunz/id1589618145"
              target="_blank"
              rel="noreferrer"
              className="m-r-10"
            >
              <img
                src={AppleStoreBtn}
                alt="apple app store button"
                className="app-btn"
              />
            </a>
            <button
              className="btn btn-large m-b-10"
              type="button"
              onClick={gotoAdminApp}
            >
              Become a Merchant today
            </button>
          </div>
          <div className="footer-others p-r-20">
            <p className="m-b-20 md-font">
              Join our mailing list and be the first to know about our new
              goodies.
            </p>
            <div className="mailing-form m-b-20">
              <input
                placeholder="Enter your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="button" onClick={subscribe} disabled={isProcessing}>
                {isProcessing ? 'Submitting...' : 'Submit'}
              </button>
            </div>
            <div className="social-medial-icons flex">
              <div className="icon m-r-20 m-b-10">
                <a
                  href="https://www.instagram.com/mycampusrunz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Instagram} alt="instagram icon" />
                </a>
              </div>
              <div className="icon m-r-20 m-b-10">
                <a
                  href="https://facebook.com/campusrunz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Like} alt="facebook like icon" />
                </a>
              </div>
              <div className="icon">
                <a
                  href="https://twitter.com/mycampusrunz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Twitter} alt="twitter icon" />
                </a>
              </div>
            </div>
            <div className="mb-1">
              <p>Contact us via telephone or whatsapp:</p>
              <a
                href="https://wa.me/2349065406276"
                target="_blank"
                rel="noopener noreferrer"
                className="block-url"
              >
                +234 906 540 6276
              </a>
              <a
                href="https://wa.me/2347067035892"
                target="_blank"
                rel="noopener noreferrer"
                className="block-url"
              >
                +234 706 703 5892
              </a>
            </div>
            <div>
              <Link to="/" className="inline-url2">
                Home
              </Link>
              <Link to="/referral/faqs" className="inline-url2">
                Referral Program
              </Link>
              <Link to="/privacy" className="inline-url2">
                Terms and Conditions
              </Link>
            </div>
            <p className="m-t-20 xsm-font">
              &copy;&nbsp;2020-
              {new Date().getFullYear()}
              &nbsp;A product of Sourceone Consulting and Technologies Limited
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
