import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import IsLoading from './layouts/IsLoading';
import { get } from '../api-services/fetch';

const CompleteFunding = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const [responseMsg, setResponseMsg] = useState('');

  useEffect(() => {
    const processCallback = async () => {
      const query = qs.parse(history.location.search);
      // console.log(query);

      if (query?.status === 'successful') {
        const res = await get({
          endpoint: 'completefunding',
          pQuery: {
            status: query?.status,
            tx_ref: query?.tx_ref,
            transaction_id: query?.transaction_id
          }
        });
        // console.log(res);

        setIsLoading(false);
        if (res?.status === 200) {
          const { data: { message } } = res;
          setIsSuccessful(true);
          setResponseMsg(message || 'You have successfully funded your wallet.');
        } else {
          setIsFailed(true);
          setResponseMsg(res?.data?.error?.message || 'We were unable to fund your wallet.');
        }
      } else {
        setIsFailed(true);
        setIsLoading(false);
        setResponseMsg('We were unable to fund your wallet.');
      }
    };

    processCallback();
  }, [history]);

  const handleCallback = () => {
    // eslint-disable-next-line no-undef
    window.location.href = 'intent://www.campusrunz.com#Intent;scheme=https;package=com.campusrunzapp;end';
  };

  return (
    <div>
      {isLoading && <IsLoading message="Fund Processing" />}

      {isSuccessful && (
        <div className="overlay">
          <div className="inner-box center-text min-300">
            <h2>Success!</h2>
            <p className="m-t-20 m-b-20">{responseMsg}</p>
            <button className="btn btn-large" type="button" onClick={handleCallback}>Return to App</button>
          </div>
        </div>
      )}

      {isFailed && (
        <div className="overlay">
          <div className="inner-box center-text min-300">
            <h2>Failed!</h2>
            <p className="m-t-20 m-b-20">
              {responseMsg}
            </p>
            <button className="btn btn-large" type="button" onClick={handleCallback}>Return to App</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompleteFunding;
